import { type LucideProps } from "lucide-react";
import { Poppins, Sora } from "next/font/google";

const poppins = Poppins({ weight: "700", subsets: ["latin"] });
const sora = Sora({ weight: "700", subsets: ["latin"] });

export const LighthouseLogo = ({ ...props }: LucideProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="256"
    height="256"
    viewBox="0 0 256 256"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_165_83)">
      <mask
        id="mask0_165_83"
        style={{ maskType: "luminance" }}
        width="256"
        height="256"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M256 0H0v256h256V0z"></path>
      </mask>
      <g mask="url(#mask0_165_83)">
        <path
          fill="#229492"
          d="M112.795 185.282a44.487 44.487 0 00-6.814-10.8 93.538 93.538 0 00-14.27-13.553 224.682 224.682 0 01-18.99-16.429 68.961 68.961 0 01-11.45-14.4 32.593 32.593 0 01-3.784-9.737 31.203 31.203 0 01-.223-8.363c.268-3.9.454-7.806.71-11.707.256-3.901.523-7.851.8-11.989a40.83 40.83 0 009.446 12.958 218.025 218.025 0 0018.375 15.894 98.147 98.147 0 0113.684 12.585 43.062 43.062 0 018.516 15.5 47.136 47.136 0 011.892 10.434c.533 7.628 1.072 15.256 1.616 22.885.137 2.237.313 4.48.492 6.722z"
        ></path>
        <path
          fill="#F0C514"
          d="M103.657 116.81a28.94 28.94 0 00-4.71-7.066 63.281 63.281 0 00-9.861-8.867 153.293 153.293 0 01-13.125-10.742 45.628 45.628 0 01-7.913-9.408 20.66 20.66 0 01-2.616-6.367 19.335 19.335 0 01-.154-5.474c.184-2.55.314-5.107.473-7.66.159-2.553.36-5.138.551-7.842a26.84 26.84 0 006.529 8.476 148.644 148.644 0 0012.7 10.409 66.603 66.603 0 019.462 8.232 27.765 27.765 0 015.888 10.137 29.341 29.341 0 011.306 6.827l1.117 14.968c.116 1.443.237 2.91.353 4.377z"
        ></path>
        <path
          fill="#EF1A14"
          d="M120.959 256.049a53.2 53.2 0 00-8.8-12.99 117.685 117.685 0 00-18.431-16.3 285.488 285.488 0 01-24.53-19.76 84.259 84.259 0 01-14.786-17.321 37.647 37.647 0 01-4.887-11.71 34.982 34.982 0 01-.29-10.058c.344-4.692.587-9.389.9-14.083.315-4.694.675-9.443 1.033-14.43a49.46 49.46 0 0012.2 15.583 277.042 277.042 0 0023.737 19.117 123.895 123.895 0 0117.673 15.14 50.88 50.88 0 0111 18.638 53.22 53.22 0 012.441 12.552c.692 9.175 1.388 18.351 2.087 27.526.213 2.698.437 5.395.653 8.096z"
        ></path>
        <path
          fill="#196CE2"
          d="M112.747 24.029H59.973a2 2 0 00-2 2v5.462a2 2 0 002 2h52.774a2 2 0 002-2v-5.462a2 2 0 00-2-2z"
        ></path>
        <path
          fill="#196CE2"
          d="M105.285 31.125h-37.85v14.194h37.85V31.125zM105.651 5.104H64.704a2 2 0 00-2 2v5.462a2 2 0 002 2h40.947a2 2 0 002-2V7.104a2 2 0 00-2-2z"
        ></path>
        <path
          fill="#196CE2"
          d="M81.754 3.19a4.918 4.918 0 119.21 3.456 4.918 4.918 0 01-9.21-3.456z"
        ></path>
        <path
          fill="#F0C514"
          d="M130.239 33.461l76.768-21.194v25.605l-76.768-4.411z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_165_83">
        <path fill="#fff" d="M0 0H256V256H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export const LighthouseLogoFull = ({
  fill = "fill-black",
  ...props
}: {
  fill?: string;
} & LucideProps) => {
  return (
    <svg
      className="h-full w-full"
      viewBox="0 0 386.788 112.00"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <style>
          {`
            .cls-1 {
              fill: #20bdb2;
            }

            .cls-2 {
              fill: #f0c514;
            }

            .cls-3 {
              fill: #e83437;
            }

            .cls-4 {
              fill: #49ace1;
            }

            .cls-5 {
              font-size: 21px;
            }

            .cls-6 {
              font-size: 59px;
            }`}
        </style>
      </defs>
      <g id="Lighthouse_Logo_Full" transform="translate(-131.212 -168)">
        <path
          id="Path_1"
          data-name="Path 1"
          className="cls-1"
          d="M274,283.434a18.812,18.812,0,0,0-2.88-4.565,39.544,39.544,0,0,0-6.032-5.729,94.988,94.988,0,0,1-8.027-6.945,29.152,29.152,0,0,1-4.84-6.087,13.778,13.778,0,0,1-1.6-4.116,13.19,13.19,0,0,1-.095-3.535c.113-1.648.192-3.3.3-4.949s.221-3.319.338-5.068a17.259,17.259,0,0,0,3.993,5.477,92.15,92.15,0,0,0,7.768,6.719,41.491,41.491,0,0,1,5.784,5.32,18.2,18.2,0,0,1,3.6,6.551,19.92,19.92,0,0,1,.8,4.411q.34,4.837.683,9.674C273.851,281.538,273.925,282.486,274,283.434Z"
          transform="translate(-115.826 -33.268)"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          className="cls-2"
          d="M266.725,269.256a12.237,12.237,0,0,0-1.991-2.987,26.764,26.764,0,0,0-4.169-3.748,64.8,64.8,0,0,1-5.548-4.543A19.287,19.287,0,0,1,251.672,254a8.732,8.732,0,0,1-1.106-2.692,8.173,8.173,0,0,1-.065-2.313c.078-1.078.133-2.159.2-3.238s.152-2.171.233-3.315a11.346,11.346,0,0,0,2.76,3.583,62.839,62.839,0,0,0,5.369,4.4,28.157,28.157,0,0,1,4,3.48,11.738,11.738,0,0,1,2.489,4.285,12.409,12.409,0,0,1,.552,2.886q.235,3.164.472,6.328C266.625,268.016,266.676,268.636,266.725,269.256Z"
          transform="translate(-112.413 -48.035)"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          className="cls-3"
          d="M280.865,291.749a22.474,22.474,0,0,0-3.721-5.491,49.75,49.75,0,0,0-7.792-6.891,120.7,120.7,0,0,1-10.369-8.353,35.609,35.609,0,0,1-6.251-7.322,15.912,15.912,0,0,1-2.066-4.95,14.787,14.787,0,0,1-.122-4.252c.145-1.983.248-3.969.382-5.953s.285-3.992.436-6.1a20.909,20.909,0,0,0,5.158,6.588,117.1,117.1,0,0,0,10.035,8.081,52.375,52.375,0,0,1,7.471,6.4,21.506,21.506,0,0,1,4.651,7.879,22.491,22.491,0,0,1,1.032,5.306q.439,5.818.882,11.636C280.678,289.468,280.774,290.608,280.865,291.749Z"
          transform="translate(-119.239 -11.669)"
        />
        <rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          className="cls-4"
          width="24"
          height="4"
          rx="2"
          transform="translate(135 182)"
        />
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          className="cls-4"
          width="16"
          height="6"
          transform="translate(139 185)"
        />
        <rect
          id="Rectangle_4"
          data-name="Rectangle 4"
          className="cls-4"
          width="19"
          height="4"
          rx="2"
          transform="translate(137 174)"
        />
        <path
          id="Polygon_1"
          data-name="Polygon 1"
          className="cls-4"
          d="M1.053,5.191a2.079,2.079,0,0,1,3.893,0h0A2.079,2.079,0,0,1,3,8H3A2.079,2.079,0,0,1,1.053,5.191Z"
          transform="translate(144 168)"
        />
        <g
          id="Group_2"
          data-name="Group 2"
          transform="translate(174.519 195.169)"
        >
          <text
            id="BY_CLOUDIFY"
            data-name="BY CLOUDIFY"
            className={`.cls-5 ${fill} font-bold tracking-[0.2em] ${sora.className}`}
            transform="translate(154.481 69.615)"
          >
            <tspan x="0" y="0">
              BY CLOUDIFY
            </tspan>
          </text>
          <text
            id="Lighthouse"
            className={`cls-6 ${fill} font-bold ${poppins.className}`}
            transform="translate(-1.519 -19.169)"
          >
            <tspan x="0" y="62">
              Lighthouse
            </tspan>
          </text>
        </g>
        <path
          id="Path_25"
          data-name="Path 25"
          className="cls-2"
          d="M202.46,478.959,234.912,470v10.824Z"
          transform="translate(-36.911 -292.972)"
        />
      </g>
    </svg>
  );
};
